var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.page},[_c('div',{class:_vm.$style.wrapper},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(
          _vm.$options.ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.CREATE,
        )}}},[_vm._v(" Создать ассортимент ")])],1),_c('el-table',{attrs:{"data":_vm.assortments,"stripe":""},on:{"sort-change":_vm.onSortChange}},[_c('el-table-column',{attrs:{"prop":"name","label":"Название","sortable":""}}),_c('el-table-column',{attrs:{"prop":"slug","label":"Имя в url","sortable":""}}),_c('el-table-column',{attrs:{"width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionButtons',{attrs:{"name":"trash","edit-link":_vm.getRoute({
              route:
                _vm.$options.ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.UPDATE,
              params: { id: scope?.row?.id },
            })},on:{"delete":function($event){return _vm.deleteAssortment(scope?.row)}}})]}}])})],1),(_vm.totalPages > 1)?_c('el-pagination',{class:_vm.$style.pagination,attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.query.limit,"total":_vm.count,"current-page":_vm.query.page},on:{"update:currentPage":function($event){return _vm.$set(_vm.query, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.query, "page", $event)},"current-change":_vm.getAssortments}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }